.bg {
  background: #e3e3e3;
}

.gamelist-skeleton > ul,
.item > li {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.gamelist-skeleton .item {
  display: grid;
  height: 60px;
  grid-column-gap: 1px;
  grid-template-columns: 1fr auto auto auto;
}
.gamelist-skeleton .sport-title .icon {
  width: 18px;
  height: 18px;
  border-radius: 5px;
}

.gamelist-skeleton .sport-title .text {
  width: 145px;
  height: 8px;
}
.gamelist-skeleton .item .game-name {
  padding: 5px 10px;
}

.gamelist-skeleton .item > li {
  background: #ffffff;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}

.gamelist-skeleton .item .game-name .name.team-1 {
  width: 52%;
}

.gamelist-skeleton .item .game-name .name.team-2 {
  width: 47%;
}

.gamelist-skeleton .item .game-name .name {
  height: 8px;
  margin: 5px 0;
}

.gamelist-skeleton .item .game-name .time {
  width: 26%;
  height: 6px;
  margin: 15px 0 0;
}

.gamelist-skeleton .item .odd-block {
  background: #f7f7f7;
  display: flex;
  align-items: center;
  width: 55px;
  height: 100%;
  padding: 10px;
}
.gamelist-skeleton .item .odd-block .odd {
  width: 100%;
  height: 10px;
}

.gamelist-skeleton .sport-title {
  display: grid;
  width: 100%;
  height: 28px;
  align-items: center;
  background: #f7f7f7;
  padding: 0 10px;
  grid-template-columns: 25px 1fr;
  grid-row-gap: 10px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.03) inset;
}

.gamelist-skeleton .dashboard-title {
  display: grid;
  width: 100%;
  height: 35px;
  align-items: center;
  background: #f0f0f0;
  padding: 0 10px;
  grid-template-columns: 28px 1fr;
  grid-row-gap: 10px;
}
