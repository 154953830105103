@font-face {
  font-family: "sport-icon";
  font-style: normal;
  src: url(./../../fonts/icomoon_sports.woff) format("woff");
}

@font-face {
  font-family: "generic-icon";
  font-style: normal;
  src: url(./../../fonts/icomoon_generic.woff) format("woff");
}

.sport-icon {
  box-sizing: border-box;
  height: 100%;
  font: 16px sport-icon;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
}

.AlpineSkiing:before {
  content: "\e64e";
}

.AustralianFootball:before {
  content: "\e605";
}

.AmericanFootball:before {
  content: "\e602";
}

.Angling:before {
  content: "\e603";
}

.Archery:before {
  content: "\e604";
}

.Badminton:before {
  content: "\e606";
}

.Balls:before {
  content: "\e607";
}

.Baseball:before {
  content: "\e608";
}

.Basketball:before {
  content: "\e609";
}

.BeachHandball:before {
  content: "\e60a";
}

.BeachFootball:before,
.BeachSoccer:before {
  content: "\e60b";
}

.BeachVolleyball:before {
  content: "\e60c";
}

.Biathlon:before {
  content: "\e60d";
}

.Bobsleigh:before {
  content: "\e60e";
}

.ClashRoyale:before {
  content: "\e91f";
}

.ShortTrackSpeedSkating:before,
.SpeedSkating:before {
  content: "\e655";
}

.Snowboarding:before {
  content: "\e650";
}

.FreestyleSkiing:before {
  content: "\e620";
}

.BallHockey:before,
.Bandy:before {
  content: "\e628";
}

.Boxing:before {
  content: "\e60f";
}

.Bridge:before {
  content: "\e610";
}

.Canoe:before,
.Canoeing:before {
  content: "\e611";
}

.Chess:before {
  content: "\e612";
}

.Cricket:before {
  content: "\e613";
}

.Curling:before {
  content: "\e614";
}

.Cycling:before {
  content: "\e615";
}

.Cyclo-Cross:before,
.VirtualBicycle:before {
  content: "\e616";
}

.Bowls:before {
  content: "\e800";
}

.Darts:before {
  content: "\e617";
}

.Diving:before {
  content: "\e618";
}

.EBasketball:before {
  content: "\e900";
}

.Electronic:before,
.ElectronicSports:before {
  content: "\e619";
}

.Eurovision:before {
  content: "\e61a";
}

.Fencing:before {
  content: "\e61c";
}

.FigureSkating:before {
  content: "\e61d";
}

.Floorball:before {
  content: "\e61e";
}

.Formula1:before {
  content: "\e61f";
}

.Futsal:before {
  content: "\2e";
}

.financials:before {
  content: "\e902";
}

.Freestyle:before,
.FreestyleWrestling:before {
  content: "\e620";
}

.GaelicFootball:before {
  content: "\e621";
}

.Golf:before {
  content: "\e622";
}

.GrassHockey:before {
  content: "\e623";
}

.Greyhounds:before {
  content: "\e624";
}

.Gymnastics:before {
  content: "\e625";
}

.Handball:before {
  content: "\e626";
}

.HardAthletics:before,
.Weightlifting:before {
  content: "\e627";
}

.Hockey:before {
  content: "\e629";
}

.HorseRacing:before {
  content: "\e62a";
}

.Hurling:before {
  content: "\e62b";
}

.IceHockey:before {
  content: "\e62c";
}

.Judo:before {
  content: "\e62d";
}

.KingOfGlory:before {
  content: "\e911";
}

.LeagueOfLegends:before,
.LeagueofLegends:before {
  content: "\22";
}

.Lacross:before {
  content: "\e62e";
}

.Athletics:before,
.LightAthletics:before {
  content: "\e62f";
}

.Lottery:before {
  content: "\e631";
}

.Luge:before {
  content: "\e632";
}

.Mafia:before {
  content: "\e633";
}

.MiniSoccer:before {
  content: "\e634";
}

.ModernPentathlon:before {
  content: "\e635";
}

.Motorbikes:before {
  content: "\e636";
}

.Motorsport:before {
  content: "\e637";
}

.Nascar:before {
  content: "\e638";
}

.Netball:before {
  content: "\e639";
}

.NordicCombined:before {
  content: "\e63a";
}

.Olimpic:before,
.Olympics:before {
  content: "\e63b";
}

.Oscar:before {
  content: "\e63c";
}

.Overwatch:before {
  content: "\2a";
}

.outright:before {
  content: "\e903";
}

.Padel:before {
  content: "\e63d";
}

.Padel-Tennis:before {
  content: "\e669";
}

.Pesapallo:before {
  content: "\e63e";
}

.PokerLive:before {
  content: "\e63f";
}

.Poker:before {
  content: "\e640";
}

.MortalKombatXL:before {
  content: "\27";
}

.E-IceHockey:before {
  content: "\e919";
}

.Politics:before {
  content: "\e641";
}

.Pool:before {
  content: "\e642";
}

.AutoRacing:before,
.Rally:before {
  content: "\e643";
}

.RinkHockey:before {
  content: "\e644";
}

.Rowing:before {
  content: "\e645";
}

.Rugby:before,
.RugbyLeague:before,
.RugbySevens:before,
.RugbyUnion:before {
  content: "\e646";
}

.Schwingen:before {
  content: "\e649";
}

.Shooting:before {
  content: "\e64a";
}

.Shorttrackspeedskating:before {
  content: "\e64b";
}

.Skeleton:before {
  content: "\e64c";
}

.StreetFighterV:before {
  content: "\e910";
}

.SkiJump:before,
.SkiJumping:before {
  content: "\e64d";
}

.Skis:before {
  content: "\e64e";
}

.CrossCountrySkiing:before,
.Winter:before,
.sport-group-Winter:before {
  content: "\e601";
}

.Snooker:before {
  content: "\e64f";
}

.PS4GAMING:before {
  content: "\e91a";
}

.Snowboard:before {
  content: "\e650";
}

.Smite:before {
  content: "\2b";
}

.mostPopular:before {
  content: "\e903";
}

.Football:before,
.MixedFootball:before,
.Soccer:before {
  content: "\E66F";
}

.ThePenaltyKicks:before {
  content: "\e918";
}

.VirtualCarRacing:before {
  content: "\e905";
}

.VirtualFootball:before {
  content: "\e651";
}

.CyberFootball:before {
  content: "\e901";
}

.SoccerWC2014:before {
  content: "\e652";
}

.Softball:before {
  content: "\e653";
}

.SpecialBets:before {
  content: "\e654";
}

.Speedskating:before {
  content: "\e655";
}

.Speedway:before {
  content: "\e656";
}

.SportAndFun:before {
  content: "\e657";
}

.SportFight:before {
  content: "\e658";
}

.Squash:before {
  content: "\e659";
}

.Sumo:before {
  content: "\e65a";
}

.Surfing:before {
  content: "\e65b";
}

.Petanque:before {
  content: "\e920";
}

.Kabaddi:before {
  content: "\e921";
}

.RainbowSix:before {
  content: "\e922";
}

.SummerOlympics:before {
  content: "\e63b";
}

.Swimming:before {
  content: "\e65c";
}

.SynchronisedSwimming:before,
.SynchronizedSwimming:before {
  content: "\e65d";
}

.Tablehockey:before {
  content: "\e65e";
}

.TableTennis:before {
  content: "\e65f";
}

.Taekwondo:before {
  content: "\e660";
}

.Equestrian:before {
  content: "\e62a";
}

.Tankbiathlon:before {
  content: "\e661";
}

.TVShowsAndMovies:before,
.Telecasts:before {
  content: "\e662";
}

.BetOnPoker:before,
.LiveBetOnPoker:before {
  content: "\e917";
}

.BetOnBlackJack:before {
  content: "\e916";
}

.RocketLeague:before {
  content: "\e913";
}

.WarcraftIII:before {
  content: "\e914";
}

.Tennis:before {
  content: "\e663";
}

.ETennis:before {
  content: "\e91e";
}

.Triathlon:before {
  content: "\e664";
}

.Trotting:before {
  content: "\e668";
}

.Mma:before,
.UFS:before {
  content: "\e665";
}

.UltimateFighting:before {
  content: "\e666";
}

.virtualdogs:before,
.virtualsports:before {
  content: "\e667";
}

.VirtualHorseRacing:before,
.virtualhorses:before {
  content: "\e668";
}

.VirtualTennis:before,
.virtualtennis:before {
  content: "\e669";
}

.Volleyball:before {
  content: "\e66a";
}

.WaterPolo:before {
  content: "\e66b";
}

.WhatWhereWhen:before {
  content: "\e66c";
}

.WinterOlympics:before {
  content: "\e63b";
}

.GrecoRomanWrestling:before,
.WWE:before,
.Wrestling:before {
  content: "\e66d";
}

.Yachting:before {
  content: "\e66e";
}

.X-Sports:before {
  content: "\e61b";
}

.VirtualHorses:before {
  content: "\e668";
}

.VirtualDogs:before,
.VirtualGreyhoundRacing:before {
  content: "\e624";
}

.CounterStrike:before,
.Global.Offensive:before {
  content: "\21";
}

.Dota2:before,
.Dota:before {
  content: "\24";
}

.Heroes.of.the.Storm:before,
.HeroesOfTheStorm:before,
.HeroesoftheStorm:before {
  content: "\25";
}

.WorldOfTanks:before,
.WorldofTanks:before {
  content: "\28";
}

.Hearthstone:before {
  content: "\26";
}

.StarCraft2:before,
.StarCraft:before {
  content: "\2d";
}

.WorldOfWarcraft:before {
  content: "\29";
}

.CallOfDuty:before,
.CallofDuty:before {
  content: "\e904";
}

.Livemonitor:before {
  content: "\e630";
}

.Confederations:before,
.body-wrapper.asian .asian-live .All.mini-icon-1:before,
.icon-sport-asia.All:before {
  content: "\e600";
}

.Vainglory:before {
  content: "\e90c";
}

.Halo:before {
  content: "\e90d";
}

.E-sports:before,
.eSports:before,
.sport-group-15:before {
  content: "\e90f";
}

.Automovilismo:before {
  content: "\e91b";
}

.FIFA:before {
  content: "\e91c";
}

.sport-group-.E-sports:before {
  content: "\e915";
}

.CrossFire:before,
.Crossfire:before,
.crossfire:before {
  content: "\e90e";
}

.generic-icon {
  box-sizing: border-box;
  height: 100%;
  font: 16px generic-icon;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
}

.fav-star-m {
  box-sizing: border-box;
  height: 100%;
  font: 16px generic-icon;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
}

.fav-star-m.active {
  background: #e3151a;
  color: #ecf3f7;
}

.fav-star-m:before {
  content: "\F005";
}
