@keyframes skeleton-k {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}

@keyframes transparent-v {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes odds-up-down {
  0%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.skeleton-loader {
  height: 20px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.1);
  animation: skeleton-k 1s ease-in-out infinite alternate-reverse;
  position: relative;
}

.odds_factor--increasing::after,
.odds_factor--decreasing::after {
  content: "";
  width: 1px;
  height: 0;
  margin-right: 3px;
  display: inline-block;
  border-style: solid;
  animation: odds-up-down 1.8s ease-in-out infinite alternate;
  transition: all 0.5s ease-in-out;
}

.odds_factor--increasing::after {
  border-width: 0 3px 5.2px 3px;
  border-color: transparent transparent #78b428 transparent;
}

.odds_factor--decreasing::after {
  border-width: 5.2px 3px 0 3px;
  margin-top: 2px;
  border-color: #e3151a transparent transparent transparent;
}

.loading-desktop {
  position: absolute;
  top: 85px;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: -1;
}

.md-progress-circular {
  overflow: hidden;
  width: 100px;
  height: 100px;
  display: block;
  vertical-align: top;
  margin: auto;
  transform: translate3d(0, 0, 0);
  animation: 400ms transparent-v ease-in-out;
}

.md-progress-circular::before {
  content: "";
  display: inline-block;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  animation: spinner 1200ms linear infinite;
}

.border-color__light {
  color: #0f75c5;
}

.border-color__dark {
  color: #bd0d12;
}
