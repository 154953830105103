body {
  font-family: Roboto, sans-serif;
  margin: 0;
  padding: 0;
  color: #747474;
  background-color: #fff;
  overflow: scroll;
}

body,
ul {
  overflow: hidden;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul p {
  margin: 0;
}

ul a {
  text-decoration: none;
  color: #4b4b4b;
  display: block;
  padding: 0;
  height: inherit;
}

#root {
  overflow: hidden;
}

.hbox--reverse {
  display: inline-flex;
  flex-direction: row-reverse;
}

.hbox {
  display: flex;
  flex-direction: row;
}

.hbox--vcenter {
  align-items: center;
}

.hbox--hcenter {
  justify-content: center;
}

.hbox--hend {
  justify-content: flex-end;
}

.hbox--hstart {
  justify-content: flex-start;
}

.hbox--vtop {
  align-items: flex-start;
}

.hbox--vbottom {
  align-items: flex-end;
}

.hbox--space-between {
  justify-content: space-between;
}

.hbox-item--flex-end {
  margin-left: auto;
}

.hbox__min-width {
  min-width: 0;
}

.vbox {
  display: flex;
  flex-direction: column;
}

.vbox__padding-r {
  padding-right: 10px;
}

.vbox--vcenter {
  justify-content: center;
}

.vbox--hcenter {
  align-items: center;
}

.vbox-item--flex-end {
  margin-top: auto;
}

.hbox__spacer,
.vbox__spacer {
  flex: 1;
}

.text--elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
}

.hpad--8 {
  padding: 0 8px;
}

.vpad--5 {
  padding: 5px 0;
}

.chevron::before {
  border-style: solid;
  border-width: 0.16em 0.16em 0 0;
  content: "";
  display: inline-block;
  height: 0.4em;
  position: relative;
  top: 0.3em;
  left: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.4em;
}

.chevron--right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron--bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron--left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

/*TODO: remove flag css from global css scope*/
.flag-icon {
  background: url("./../../images/flags.png") no-repeat 0 0/100%;
  width: 22px;
  height: 22px;
  margin-right: 5px;
  image-rendering: pixelated;
}

.flag-icon__dark {
  background: url("./../../images/flags.png") no-repeat 0 0/100%;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  image-rendering: pixelated;
}

.flag-icon__light {
  background: url("./../../images/flags.png") no-repeat 0 0/100%;
  width: 16px;
  height: 16px;
  margin-right: 5px;
  image-rendering: pixelated;
}
.flag-asean {
  background-position: 0 0;
}

.flag-afghanistan {
  background-position: 0 0.346021%;
}

.flag-africa {
  background-position: 0 0.692042%;
}

.flag-aland {
  background-position: 0 1.038062%;
}

.flag-albania {
  background-position: 0 1.384083%;
}

.flag-alderney {
  background-position: 0 1.730104%;
}

.flag-algeria {
  background-position: 0 2.076125%;
}

.flag-all {
  background-position: 0 2.422145%;
}

.flag-americansamoa {
  background-position: 0 2.768166%;
}

.flag-andorra {
  background-position: 0 3.114187%;
}

.flag-angola {
  background-position: 0 3.460208%;
}

.flag-anguilla {
  background-position: 0 3.806228%;
}

.flag-antiguaandbarbuda {
  background-position: 0 4.152249%;
}

.flag-arableague {
  background-position: 0 4.49827%;
}

.flag-argentina {
  background-position: 0 4.844291%;
}

.flag-armenia {
  background-position: 0 5.190311%;
}

.flag-aruba {
  background-position: 0 5.536332%;
}

.flag-asia {
  background-position: 0 5.882353%;
}

.flag-australia {
  background-position: 0 6.228374%;
}

.flag-austria {
  background-position: 0 6.574394%;
}

.flag-azerbaijan {
  background-position: 0 6.920415%;
}

.flag-bahamas {
  background-position: 0 7.266436%;
}

.flag-bahrain {
  background-position: 0 7.612457%;
}

.flag-bangladesh {
  background-position: 0 7.958478%;
}

.flag-barbados {
  background-position: 0 8.304498%;
}

.flag-basquecountry {
  background-position: 0 8.650519%;
}

.flag-belarus {
  background-position: 0 8.99654%;
}

.flag-belgium {
  background-position: 0 9.342561%;
}

.flag-belize {
  background-position: 0 9.688581%;
}

.flag-benin {
  background-position: 0 10.034602%;
}

.flag-bermuda {
  background-position: 0 10.380623%;
}

.flag-bhutan {
  background-position: 0 10.726644%;
}

.flag-bolivia {
  background-position: 0 11.072664%;
}

.flag-bonairesinteustatiusandsaba {
  background-position: 0 11.418685%;
}

.flag-bosniaherzegovina {
  background-position: 0 11.764706%;
}

.flag-botswana {
  background-position: 0 12.110727%;
}

.flag-bouvet {
  background-position: 0 12.456747%;
}

.flag-brazil {
  background-position: 0 12.802768%;
}

.flag-britishindianoceanterritory {
  background-position: 0 13.148789%;
}

.flag-brunei {
  background-position: 0 13.49481%;
}

.flag-bulgaria {
  background-position: 0 13.84083%;
}

.flag-burkinafaso {
  background-position: 0 14.186851%;
}

.flag-burundi {
  background-position: 0 14.532872%;
}

.flag-caricom {
  background-position: 0 14.878893%;
}

.flag-cis {
  background-position: 0 15.224913%;
}

.flag-cambodja {
  background-position: 0 15.570934%;
}

.flag-cameroon {
  background-position: 0 15.916955%;
}

.flag-canada {
  background-position: 0 16.262976%;
}

.flag-capeverde {
  background-position: 0 16.608997%;
}

.flag-catalonia {
  background-position: 0 16.955017%;
}

.flag-caymanislands {
  background-position: 0 17.301038%;
}

.flag-centralafricanrepublic {
  background-position: 0 17.647059%;
}

.flag-chad {
  background-position: 0 17.99308%;
}

.flag-chile {
  background-position: 0 18.3391%;
}

.flag-china {
  background-position: 0 18.685121%;
}

.flag-chinesetaipei {
  background-position: 0 19.031142%;
}

.flag-christmas {
  background-position: 0 19.377163%;
}

.flag-cocoskeeling {
  background-position: 0 19.723183%;
}

.flag-colombia {
  background-position: 0 20.069204%;
}

.flag-commonwealth {
  background-position: 0 20.415225%;
}

.flag-comoros {
  background-position: 0 20.761246%;
}

.flag-congobrazzaville {
  background-position: 0 21.107266%;
}

.flag-congokinshasa {
  background-position: 0 21.453287%;
}

.flag-cookislands {
  background-position: 0 21.799308%;
}

.flag-costarica {
  background-position: 0 22.145329%;
}

.flag-cotedivoire {
  background-position: 0 22.491349%;
}

.flag-croatia {
  background-position: 0 22.83737%;
}

.flag-cuba {
  background-position: 0 23.183391%;
}

.flag-curacao {
  background-position: 0 23.529412%;
}

.flag-cyprus {
  background-position: 0 23.875433%;
}

.flag-czechrepublic {
  background-position: 0 24.221453%;
}

.flag-denmark {
  background-position: 0 24.567474%;
}

.flag-djibouti {
  background-position: 0 24.913495%;
}

.flag-dominica {
  background-position: 0 25.259516%;
}

.flag-dominicanrepublic {
  background-position: 0 25.605536%;
}

.flag-ecuador {
  background-position: 0 25.951557%;
}

.flag-egypt {
  background-position: 0 26.297578%;
}

.flag-elsalvador {
  background-position: 0 26.643599%;
}

.flag-england {
  background-position: 0 26.989619%;
}

.flag-equatorialguinea {
  background-position: 0 27.33564%;
}

.flag-eritrea {
  background-position: 0 27.681661%;
}

.flag-estonia {
  background-position: 0 28.027682%;
}

.flag-ethiopia {
  background-position: 0 28.373702%;
}

.flag-europe {
  background-position: 0 28.719723%;
}

.flag-fao {
  background-position: 0 29.065744%;
}

.flag-falkland {
  background-position: 0 29.411765%;
}

.flag-faroes {
  background-position: 0 29.757785%;
}

.flag-fiji {
  background-position: 0 30.103806%;
}

.flag-finland {
  background-position: 0 30.449827%;
}

.flag-france {
  background-position: 0 30.795848%;
}

.flag-french-guiana {
  background-position: 0 31.141869%;
}

.flag-frenchsouthernandantarcticlands {
  background-position: 0 31.487889%;
}

.flag-gabon {
  background-position: 0 31.83391%;
}

.flag-galicia {
  background-position: 0 32.179931%;
}

.flag-gambia {
  background-position: 0 32.525952%;
}

.flag-georgia {
  background-position: 0 32.871972%;
}

.flag-germany {
  background-position: 0 33.217993%;
}

.flag-ghana {
  background-position: 0 33.564014%;
}

.flag-gibraltar {
  background-position: 0 33.910035%;
}

.flag-greatbritain {
  background-position: 0 34.256055%;
}

.flag-greece {
  background-position: 0 34.602076%;
}

.flag-greenland {
  background-position: 0 34.948097%;
}

.flag-grenada {
  background-position: 0 35.294118%;
}

.flag-guadeloupe {
  background-position: 0 35.640138%;
}

.flag-guam {
  background-position: 0 35.986159%;
}

.flag-guatemala {
  background-position: 0 36.33218%;
}

.flag-guernsey {
  background-position: 0 36.678201%;
}

.flag-guinea {
  background-position: 0 37.024221%;
}

.flag-guineabissau {
  background-position: 0 37.370242%;
}

.flag-guyana {
  background-position: 0 37.716263%;
}

.flag-haiti {
  background-position: 0 38.062284%;
}

.flag-heardislandandmcdonald {
  background-position: 0 38.408304%;
}

.flag-honduras {
  background-position: 0 38.754325%;
}

.flag-hongkong {
  background-position: 0 39.100346%;
}

.flag-hungary {
  background-position: 0 39.446367%;
}

.flag-iaea {
  background-position: 0 39.792388%;
}

.flag-iho {
  background-position: 0 40.138408%;
}

.flag-iceland {
  background-position: 0 40.484429%;
}

.flag-india {
  background-position: 0 40.83045%;
}

.flag-indonesia {
  background-position: 0 41.176471%;
}

.flag-international {
  background-position: 0 41.522491%;
}

.flag-iran {
  background-position: 0 41.868512%;
}

.flag-iraq {
  background-position: 0 42.214533%;
}

.flag-ireland {
  background-position: 0 42.560554%;
}

.flag-islamicconference {
  background-position: 0 42.906574%;
}

.flag-isleofman {
  background-position: 0 43.252595%;
}

.flag-israel {
  background-position: 0 43.598616%;
}

.flag-italy {
  background-position: 0 43.944637%;
}

.flag-jamaica {
  background-position: 0 44.290657%;
}

.flag-japan {
  background-position: 0 44.636678%;
}

.flag-jersey {
  background-position: 0 44.982699%;
}

.flag-jordan {
  background-position: 0 45.32872%;
}

.flag-kazakhstan {
  background-position: 0 45.67474%;
}

.flag-kenya {
  background-position: 0 46.020761%;
}

.flag-kiribati {
  background-position: 0 46.366782%;
}

.flag-kosovo {
  background-position: 0 46.712803%;
}

.flag-kurdish {
  background-position: 0 47.058824%;
}

.flag-kuwait {
  background-position: 0 47.404844%;
}

.flag-kyrgyzstan {
  background-position: 0 47.750865%;
}

.flag-laos {
  background-position: 0 48.096886%;
}

.flag-latvia {
  background-position: 0 48.442907%;
}

.flag-lebanon {
  background-position: 0 48.788927%;
}

.flag-lesotho {
  background-position: 0 49.134948%;
}

.flag-liberia {
  background-position: 0 49.480969%;
}

.flag-libya {
  background-position: 0 49.82699%;
}

.flag-liechtenshein {
  background-position: 0 50.17301%;
}

.flag-lithuania {
  background-position: 0 50.519031%;
}

.flag-luxembourg {
  background-position: 0 50.865052%;
}

.flag-macao {
  background-position: 0 51.211073%;
}

.flag-macedonia,
.flag-northmacedonia {
  background-position: 0 51.557093%;
}

.flag-madagascar {
  background-position: 0 51.903114%;
}

.flag-malawi {
  background-position: 0 52.249135%;
}

.flag-malaysia {
  background-position: 0 52.595156%;
}

.flag-maldives {
  background-position: 0 52.941176%;
}

.flag-mali {
  background-position: 0 53.287197%;
}

.flag-malta {
  background-position: 0 53.633218%;
}

.flag-marshallislands {
  background-position: 0 53.979239%;
}

.flag-martinique {
  background-position: 0 54.32526%;
}

.flag-mauritania {
  background-position: 0 54.67128%;
}

.flag-mauritius {
  background-position: 0 55.017301%;
}

.flag-mayotte {
  background-position: 0 55.363322%;
}

.flag-mexico {
  background-position: 0 55.709343%;
}

.flag-micronesia {
  background-position: 0 56.055363%;
}

.flag-moldova {
  background-position: 0 56.401384%;
}

.flag-monaco {
  background-position: 0 56.747405%;
}

.flag-mongolia {
  background-position: 0 57.093426%;
}

.flag-montenegro {
  background-position: 0 57.439446%;
}

.flag-montserrat {
  background-position: 0 57.785467%;
}

.flag-morocco {
  background-position: 0 58.131488%;
}

.flag-mozambique {
  background-position: 0 58.477509%;
}

.flag-myanmar {
  background-position: 0 58.823529%;
}

.flag-nato {
  background-position: 0 59.16955%;
}

.flag-namibia {
  background-position: 0 59.515571%;
}

.flag-nauru {
  background-position: 0 59.861592%;
}

.flag-nepal {
  background-position: 0 60.207612%;
}

.flag-netherlands {
  background-position: 0 60.553633%;
}

.flag-netherlandsantilles {
  background-position: 0 60.899654%;
}

.flag-newcaledonia {
  background-position: 0 61.245675%;
}

.flag-newzealand {
  background-position: 0 61.591696%;
}

.flag-nicaragua {
  background-position: 0 61.937716%;
}

.flag-niger {
  background-position: 0 62.283737%;
}

.flag-nigeria {
  background-position: 0 62.629758%;
}

.flag-niue {
  background-position: 0 62.975779%;
}

.flag-norfolk {
  background-position: 0 63.321799%;
}

.flag-northamerica {
  background-position: 0 63.66782%;
}

.flag-northkorea {
  background-position: 0 64.013841%;
}

.flag-northerncyprus {
  background-position: 0 64.359862%;
}

.flag-northernireland {
  background-position: 0 64.705882%;
}

.flag-northernmariana {
  background-position: 0 65.051903%;
}

.flag-norway {
  background-position: 0 65.397924%;
}

.flag-oas {
  background-position: 0 65.743945%;
}

.flag-opec {
  background-position: 0 66.089965%;
}

.flag-oceania {
  background-position: 0 66.435986%;
}

.flag-olimpics {
  background-position: 0 66.782007%;
}

.flag-oman {
  background-position: 0 67.128028%;
}

.flag-pakistan {
  background-position: 0 67.474048%;
}

.flag-palau {
  background-position: 0 67.820069%;
}

.flag-palestine {
  background-position: 0 68.16609%;
}

.flag-panama {
  background-position: 0 68.512111%;
}

.flag-papuanewguinea {
  background-position: 0 68.858131%;
}

.flag-paraguay {
  background-position: 0 69.204152%;
}

.flag-peru {
  background-position: 0 69.550173%;
}

.flag-philippines {
  background-position: 0 69.896194%;
}

.flag-pitcairn {
  background-position: 0 70.242215%;
}

.flag-poland {
  background-position: 0 70.588235%;
}

.flag-portugal {
  background-position: 0 70.934256%;
}

.flag-puertorico {
  background-position: 0 71.280277%;
}

.flag-qatar {
  background-position: 0 71.626298%;
}

.flag-quebec {
  background-position: 0 71.972318%;
}

.flag-redcross {
  background-position: 0 72.318339%;
}

.flag-reunion {
  background-position: 0 72.66436%;
}

.flag-romania {
  background-position: 0 73.010381%;
}

.flag-russia {
  background-position: 0 73.356401%;
}

.flag-rwanda {
  background-position: 0 73.702422%;
}

.flag-saintbarthelemy {
  background-position: 0 74.048443%;
}

.flag-sainthelena {
  background-position: 0 74.394464%;
}

.flag-saintlucia {
  background-position: 0 74.740484%;
}

.flag-saintmartin {
  background-position: 0 75.086505%;
}

.flag-saintpierreandmiquelon {
  background-position: 0 75.432526%;
}

.flag-samoa {
  background-position: 0 75.778547%;
}

.flag-sanmarino {
  background-position: 0 76.124567%;
}

.flag-saotomeandprincipe {
  background-position: 0 76.470588%;
}

.flag-saudiarabia {
  background-position: 0 76.816609%;
}

.flag-scotland {
  background-position: 0 77.16263%;
}

.flag-senegal {
  background-position: 0 77.508651%;
}

.flag-serbia {
  background-position: 0 77.854671%;
}

.flag-seychelles {
  background-position: 0 78.200692%;
}

.flag-sierraleone {
  background-position: 0 78.546713%;
}

.flag-singapore {
  background-position: 0 78.892734%;
}

.flag-sintmaarten {
  background-position: 0 79.238754%;
}

.flag-slovakia {
  background-position: 0 79.584775%;
}

.flag-slovenia {
  background-position: 0 79.930796%;
}

.flag-solomonislands {
  background-position: 0 80.276817%;
}

.flag-somalia {
  background-position: 0 80.622837%;
}

.flag-somaliland {
  background-position: 0 80.968858%;
}

.flag-southafrica {
  background-position: 0 81.314879%;
}

.flag-southamerica {
  background-position: 0 81.6609%;
}

.flag-southgeorgiaandsouthsandwich {
  background-position: 0 82.00692%;
}

.flag-southkorea {
  background-position: 0 82.352941%;
}

.flag-southsudan {
  background-position: 0 82.698962%;
}

.flag-spain {
  background-position: 0 83.044983%;
}

.flag-srilanka {
  background-position: 0 83.391003%;
}

.flag-stkittsandnevis {
  background-position: 0 83.737024%;
}

.flag-stvincentandthegrenadines {
  background-position: 0 84.083045%;
}

.flag-sudan {
  background-position: 0 84.429066%;
}

.flag-suriname {
  background-position: 0 84.775087%;
}

.flag-svalbardandjanmayen {
  background-position: 0 85.121107%;
}

.flag-swaziland {
  background-position: 0 85.467128%;
}

.flag-sweden {
  background-position: 0 85.813149%;
}

.flag-switzerland {
  background-position: 0 86.15917%;
}

.flag-syria {
  background-position: 0 86.50519%;
}

.flag-tahiti {
  background-position: 0 86.851211%;
}

.flag-taiwan {
  background-position: 0 87.197232%;
}

.flag-tajikistan {
  background-position: 0 87.543253%;
}

.flag-United.Republic.of.Tanzania,
.flag-tanzania {
  background-position: 0 87.889273%;
}

.flag-thailand {
  background-position: 0 88.235294%;
}

.flag-timorleste {
  background-position: 0 88.581315%;
}

.flag-togo {
  background-position: 0 88.927336%;
}

.flag-tokelau {
  background-position: 0 89.273356%;
}

.flag-tonga {
  background-position: 0 89.619377%;
}

.flag-trinidadtobago {
  background-position: 0 89.965398%;
}

.flag-tristandacunha {
  background-position: 0 90.311419%;
}

.flag-tunisia {
  background-position: 0 90.657439%;
}

.flag-turkey {
  background-position: 0 91.00346%;
}

.flag-turkmenistan {
  background-position: 0 91.349481%;
}

.flag-turksandcaicosislands {
  background-position: 0 91.695502%;
}

.flag-tuvalu {
  background-position: 0 92.041522%;
}

.flag-usa {
  background-position: 0 92.387543%;
}

.flag-uganda {
  background-position: 0 92.733564%;
}

.flag-ukraine {
  background-position: 0 93.079585%;
}

.flag-uae,
.flag-unitedarabemirates {
  background-position: 0 93.425606%;
}

.flag-unitedkingdom {
  background-position: 0 93.771626%;
}

.flag-uruguay {
  background-position: 0 94.117647%;
}

.flag-uzbekistan {
  background-position: 0 94.463668%;
}

.flag-vanutau {
  background-position: 0 94.809689%;
}

.flag-vaticancity {
  background-position: 0 95.155709%;
}

.flag-venezuela {
  background-position: 0 95.50173%;
}

.flag-vietnam {
  background-position: 0 95.847751%;
}

.flag-virginislandsbritish {
  background-position: 0 96.193772%;
}

.flag-virginislandsus {
  background-position: 0 96.539792%;
}

.flag-wales {
  background-position: 0 96.885813%;
}

.flag-wallisandfutuna {
  background-position: 0 97.231834%;
}

.flag-westernsahara {
  background-position: 0 97.577855%;
}

.flag-world {
  background-position: 0 97.923875%;
}

.flag-yemen {
  background-position: 0 98.269896%;
}

.flag-zambia {
  background-position: 0 98.615917%;
}

.flag-zimbabwe {
  background-position: 0 98.961938%;
}

.flag-allupcoming {
  background-position: 0 99.307958%;
}

.flag-america {
  background-position: 0 99.653979%;
}
