.events-main-list .event-list__item:nth-child(1) {
  border: none;
}

.events-main-list .list__title {
  padding: 0;
}

.hide {
  display: none;
}

.events-list__main-header {
  position: relative;
  height: inherit;
  padding: 0 0 0 7px;
  text-transform: uppercase;
  font-size: 12px;
}

.events-list__main-header .events-list__header__icon {
  font-size: 18px;
  font-family: "generic-icon";
  padding-right: 7px;
}

.events-list:first-child {
  border-top: 1px solid #ddd;
}

.events-list .event-list__item:nth-child(1) {
  border-bottom: 1px solid #ddd;
}

.event-list__title {
  height: inherit;
  padding: 0 0 0 8px;
  background: #fff;
  color: #4b4b4b;
  font-size: 14px;
  font-weight: 600;
}

/* .events-list .list-item:last-child {
  border-bottom: 2px solid #e8e8e8;
} */

.events-list .list__title {
  padding: 0 0 0 8px;
}

/* .events-list .list-item:nth-child(1) {
  /*border-bottom: 1.5px solid #e8e8e8; */ /*
  border-bottom: 1px solid #ddd;
}  */

.event-list__item {
  background: #fff;
  box-shadow: 0 1px 0 0 #ddd;
  height: 30px;
  text-align: left;
  cursor: pointer;
}

.event-list-item--large {
  height: auto;
}

.event-list-item__content {
  height: inherit;
  color: #4b4b4b;
  font-size: 12px;
}

.events-list .event-list__item {
  /*border-bottom: 1.5px solid #e8e8e8; */
  border-bottom: 1px solid #ddd;
}

/* .events-list--live .list__title {
  background: #fff;
  font-size: 12px;
} */

.events-list__header__icon.sport-icon {
  width: auto;
  padding-right: 8px;
}

.live-events-icon {
  font-family: generic-icon;
  padding-right: 5px;
}

.live-events-icon::before {
  content: "\e936";
  color: #e3151a;
}

.upcoming-events-icon::before {
  content: "\e6e2";
  color: #78b428;
}

.event__title {
  min-width: 0;
  font-size: 14px;
  padding: 3px 8px;
}

.event__title > p {
  padding: 3px 0 0;
}
.event__timestamp {
  font-size: 10px;
  color: #aaa;
  margin: 0;
  padding: 5px 0 0;
}

.event__live-info__game-state {
  font-size: 10px;
  margin-top: 5px;
}

.event__live-info__markets-count {
  display: flex;
  font-size: 13px;
}

.market-num {
  padding-bottom: 2px;
}

.event__live-info__markets-count .chevron--right {
  padding-left: 3px;
}

.score-rows {
  display: grid;
  grid-template-rows: 1fr 1fr;
}
.score-cols {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.event__team-score {
  color: #0f75c5;
  font-size: 14px;
  margin-top: 3px;
  min-width: 18px;
  text-align: center;
}

.event__team-score-black {
  color: black;
  font-size: 14px;
  margin-top: 3px;
  min-width: 18px;
  text-align: center;
}

.event__odds {
  height: inherit;
  max-width: 160px;
  /* flex-basis: 40%;
  min-width: 40%;
  max-width: 40%; */
}

.events-list .event__odds {
  max-width: none;
  flex: 1;
}

.event__odds__button {
  min-width: 0;
  /* padding: 5px 0 5px 0; */
  margin: 2px 2px 2px 0;
}

.events-list .event__odds__button {
  border-left: 1px solid #e8e8e8;
  /* flex: 1; */
}

.lock-icon::after {
  font-family: "generic-icon";
  content: "\e68f";
}

.event__result {
  text-align: center;
  font-size: 9px;
}

.odds_factor {
  /* box-shadow: inset 0 1px 0 hsla(224, 9%, 53%, .15), inset 1px 0 0 #e6e0e2; */
  font-size: 14px;
  background-color: #f6f6f6;
  color: #0f75c5;
  font-weight: 400;
  text-align: center;
  width: 45px;
}

.events-list .odds_factor {
  /* width: auto; */
  width: 55px;
  font-size: 13px;
}

.odds_factor--blocked {
  background-color: transparent;
  color: #4b4b4b;
  padding-top: 10px;
  pointer-events: none;
}

.lock-icon::after {
  font-family: "generic-icon";
  content: "\e68f";
}

.odds_factor--selected {
  background: #0f75c5;
  color: white;
}

.event__outcome {
  min-width: 0;
  /*  width: 45px; */
  width: 52px;
  padding: 5px 0 5px 0;
  margin-right: 2px;
  text-align: center;
  font-weight: normal;
}

.events-list .event__outcome {
  font-weight: 800;
  /* width: auto;
  flex: 1; */
}

.go-to-league-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  padding-left: 10px;
  border-bottom: 0.5px solid #dddddd;
  color: #4b4b4b;
  font-weight: 400;
  font-family: Roboto, sans-serif;
  background: #f6f6f6;
  cursor: pointer;
}

.go-to-league-button-arrow {
  background: white;
  color: black;
  height: 100%;
  width: 32px;
}

.go-to-league-button-arrow::before {
  font-family: "generic-icon";
  content: "\E672";
  font-size: 20px;
  text-align: center;
  line-height: 32px;
  width: 100%;
  height: 100%;
  display: block;
}

.collapse-arrow {
  color: black;
  height: 100%;
  margin-top: 2px;
  width: 32px;
}

.collapse-arrow::before {
  font-family: "generic-icon";
  content: "\E671";
  font-size: 18px;
  text-align: center;
  line-height: 32px;
  font-weight: 100;
  width: 100%;
  height: 100%;
  display: block;
}

.arrow-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  width: 100%;
}

.list__title--background {
  height: 30px;
}

/* red - blue
.OlyBoosts {
  background: url("./../../images/OlyBoost_logo.jpg"),
    linear-gradient(137deg, rgba(245, 21, 21, 1) 41%, rgba(0, 0, 231, 1) 55%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
}
*/

/* red - blue  small */
.OlyBoosts-background {
  background: linear-gradient(78deg, #f51515 2%, #0021c6 20%);
}
.OlyBoosts {
  background: url("./../../images/OlyBoost_logo.jpg");
  background-size: auto 20px;
  background-repeat: no-repeat;
  background-position-y: center;
}

@media only screen and (max-width: 280px) {
  .text--small {
    font-size: 11px;
  }

  .OlyBoosts-background {
    background: linear-gradient(92deg, #f51515 2%, #0021c6 30%);
  }
}
